<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showForm"
        max-width="1000px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <v-icon class="mr-2">mdi-tag-multiple</v-icon>
            {{ formTitle }}
          </v-card-title>
          <v-divider></v-divider>
          <v-progress-linear
            v-if="progress"
            indeterminate
            :size="30"
            :width="7"
            color="purple"
          ></v-progress-linear>

          <v-card-text class="mt-6">
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  autocomplete="off"
                  dense
                  v-model="editedItem.name"
                  :readonly="readonly || editedItem.countvalues > 0"
                  label="Nom"
                  :rules="[(v) => !!v || 'Nom obligatoire']"
                  hide-spin-buttons
                  @focus="$event.target.select()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  autocomplete="off"
                  dense
                  v-model="editedItem.caption"
                  :readonly="readonly"
                  label="Caption"
                  hide-spin-buttons
                  @focus="$event.target.select()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2">
                <v-text-field
                  autocomplete="off"
                  dense
                  v-model="editedItem.suffixe"
                  :readonly="readonly"
                  label="Suffixe"
                  hide-spin-buttons
                  @focus="$event.target.select()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="3" md="3">
                <v-select
                  v-model="editedItem.type_attr"
                  :items="['variante', 'colis']"
                  :readonly="readonly || editedItem.countvalues > 0"
                  label="Categorie"
                  :rules="[(v) => !!v || 'Categorie obligatoire']"
                  dense
                >
                </v-select>
              </v-col>
              <v-col
                cols="12"
                sm="3"
                md="3"
                v-if="editedItem.type_attr == 'colis'"
              >
                <v-text-field
                  autocomplete="off"
                  type="number"
                  dense
                  v-model.number="editedItem.width"
                  :readonly="readonly"
                  label="Largeur(%)"
                  hide-spin-buttons
                  @focus="$event.target.select()"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="3"
                md="3"
                v-if="editedItem.type_attr == 'colis'"
              >
                <v-select
                  v-model="editedItem.align"
                  :items="['Gauche', 'Centre', 'Droite']"
                  :readonly="readonly"
                  label="Alignement"
                  dense
                >
                </v-select>
              </v-col>

              <v-col cols="12" sm="3" md="3">
                <v-select
                  v-model="editedItem.val_type"
                  :items="['text', 'number', 'date']"
                  :readonly="
                    readonly ||
                    (editedItem.countvalues > 0 && !$store.state.isadmin)
                  "
                  label="Type de valeur"
                  :rules="[(v) => !!v || 'Type de valeur obligatoire']"
                  dense
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-select
                  v-model="editedItem.display"
                  :items="['Liste', 'Valeur', 'Fichier Produits']"
                  :readonly="readonly || editedItem.countvalues > 0"
                  label="Affichage"
                  :rules="[(v) => !!v || 'Affichage obligatoire']"
                  v-if="editedItem.type_attr == 'variante'"
                  dense
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-autocomplete
                  v-model="editedItem.default_id"
                  :item-text="'name'"
                  :item-value="'id'"
                  :items="val_list"
                  label="Valeur par default"
                  :readonly="readonly"
                  v-if="editedItem.display == 'Liste' && editedItem.id > 0"
                  dense
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="3"
                md="3"
                v-if="editedItem.type_attr == 'variante'"
              >
                <v-checkbox
                  v-model="editedItem.incl_buy"
                  label="Inclu Achat"
                  :readonly="
                    readonly ||
                    (editedItem.countvalues > 0 && !$store.state.isadmin)
                  "
                  dense
                ></v-checkbox>
              </v-col>
              <v-col
                cols="12"
                sm="3"
                md="3"
                v-if="
                  editedItem.type_attr == 'variante' && !editedItem.technique
                "
              >
                <v-checkbox
                  v-model="editedItem.incl_manuf"
                  label="Inclu Fabrication"
                  :readonly="
                    readonly ||
                    (editedItem.countvalues > 0 && !$store.state.isadmin)
                  "
                  dense
                ></v-checkbox>
              </v-col>
              <v-col
                cols="12"
                sm="3"
                md="3"
                v-if="
                  editedItem.type_attr == 'variante' && !editedItem.technique
                "
              >
                <v-checkbox
                  v-model="editedItem.incl_sell"
                  label="Inclu Vente"
                  :readonly="
                    readonly ||
                    (editedItem.countvalues > 0 && !$store.state.isadmin)
                  "
                  dense
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="2" md="2">
                <v-checkbox
                  v-model="editedItem.is_null"
                  label="Nullable"
                  :readonly="readonly"
                  dense
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="2"
                md="2"
                v-if="editedItem.type_attr == 'variante'"
              >
                <v-checkbox
                  v-model="editedItem.technique"
                  label="Technique"
                  :readonly="readonly"
                  dense
                ></v-checkbox>
              </v-col>
              <v-col
                cols="12"
                sm="2"
                md="2"
                v-if="editedItem.type_attr == 'variante'"
              >
                <v-checkbox
                  v-model="editedItem.hyritage"
                  label="Heritage"
                  :readonly="readonly"
                  :disabled="editedItem.control == '1'"
                  dense
                ></v-checkbox>
              </v-col>
              <v-col
                cols="12"
                sm="2"
                md="2"
                v-if="
                  editedItem.type_attr == 'variante' &&
                  editedItem.val_type == 'number'
                "
              >
                <v-checkbox
                  v-model="editedItem.control"
                  label="Contrôle"
                  :readonly="readonly"
                  :disabled="editedItem.hyritage == '1'"
                  dense
                ></v-checkbox>
              </v-col>

              <v-col
                cols="12"
                sm="4"
                md="4"
                v-if="
                  editedItem.type_attr == 'variante' &&
                  editedItem.val_type == 'number'
                "
              >
                <v-autocomplete
                  :items="units.filter((elm) => elm.uom_type == 'm')"
                  v-model="editedItem.fk_unit_m2"
                  item-text="name"
                  item-value="id"
                  label="Unité de Conversion au M2"
                  outlined
                  dense
                  clearable
                  :disabled="editedItem.laize == 1"
                  :readonly="readonly"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="2"
                md="2"
                v-if="
                  editedItem.type_attr == 'variante' &&
                  editedItem.val_type == 'number'
                "
              >
                <v-checkbox
                  v-model="editedItem.laize"
                  label="Laize"
                  :readonly="readonly"
                  :disabled="editedItem.fk_unit_m2 > 0"
                  dense
                ></v-checkbox>
              </v-col>
              <v-col
                cols="12"
                sm="2"
                md="2"
                v-if="
                  editedItem.type_attr == 'variante' &&
                  editedItem.val_type == 'number'
                "
              >
                <v-checkbox
                  v-model="editedItem.is_packaging"
                  label="Conditionnement"
                  :readonly="readonly"
                  dense
                ></v-checkbox>
              </v-col>
              <!-- Unite -->
              <v-col
                cols="12"
                sm="3"
                md="3"
                v-if="editedItem.control == '1' || editedItem.laize == '1'"
              >
                <v-autocomplete
                  :items="
                    units.filter(
                      (elm) =>
                        elm.uom_type == 'm' &&
                        (editedItem.laize == '1'
                          ? elm.category_id == '4'
                          : true)
                    )
                  "
                  v-model="editedItem.fk_unit"
                  item-text="name"
                  item-value="id"
                  label="Unité de Mesure"
                  :rules="[(v) => !!v || 'Unité de Mesure obligatoire']"
                  outlined
                  dense
                  :readonly="readonly"
                >
                </v-autocomplete>
              </v-col>
              <!-- Unite -->
            </v-row>
          </v-card-text>
          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="save" v-if="!readonly">
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" text @click.stop="close">
              Fermer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
//const cursorselect = () => import("./CursorSelect.vue");
import CREATE_ATTRIBUT from "../graphql/Template/CREATE_ATTRIBUT.gql";
import UPDATE_ATTRIBUT from "../graphql/Template/UPDATE_ATTRIBUT.gql";

export default {
  components: {},
  name: "attributform",
  props: {
    item: Object,
    val_list: Array,
    readonly: Boolean,
    showForm: Boolean,
    units: Array,
  },
  data: () => ({
    progress: false,
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",

    editedItem: {},
  }),

  computed: {
    formTitle() {
      if (this.item)
        return this.item.id < 0
          ? "Nouveau Attribut "
          : "Attribut N° ".concat(this.item.id);
      else return "";
    },
  },
  watch: {},

  created() {},
  mounted() {
    if (this.item) {
      this.editedItem = Object.assign({}, this.item);
    }
  },

  methods: {
    close() {
      this.$emit("close", this.items);
    },
    async maj(query, v) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async save() {
      if (this.$refs.form.validate()) {
        if (this.editedItem.id > 0) {
          let v = {
            attribut: {
              id: this.editedItem.id,
              name: this.editedItem.name,
              caption: this.editedItem.caption,
              suffixe: this.editedItem.suffixe,
              type_attr: this.editedItem.type_attr,
              width: this.editedItem.width,
              display: this.editedItem.display,
              default_id: this.editedItem.default_id,
              hyritage: this.editedItem.hyritage ? 1 : 0,
              control: this.editedItem.control ? 1 : 0,
              incl_sell: this.editedItem.incl_sell ? 1 : 0,
              incl_buy: this.editedItem.incl_buy ? 1 : 0,
              incl_manuf: this.editedItem.incl_manuf ? 1 : 0,
              technique: this.editedItem.technique ? 1 : 0,
              is_null: this.editedItem.is_null ? 1 : 0,
              laize: this.editedItem.laize ? 1 : 0,
              is_packaging: this.editedItem.is_packaging ? 1 : 0,
              val_type: this.editedItem.val_type,
              fk_unit: this.editedItem.fk_unit,
              fk_unit_m2: this.editedItem.fk_unit_m2,
              align: this.editedItem.align,
              key: this.editedItem.key,
              write_uid: this.$store.state.me.id,
            },
          };
          await this.maj(UPDATE_ATTRIBUT, v);
          this.$store.dispatch("Changed", true);
          this.progress = false;

          this.close();
        } else {
          let v = {
            attribut: {
              name: this.editedItem.name,
              caption: this.editedItem.caption,
              suffixe: this.editedItem.suffixe,
              type_attr: this.editedItem.type_attr,
              width: this.editedItem.width,
              display: this.editedItem.display,
              default_id: this.editedItem.default_id,
              hyritage: this.editedItem.hyritage ? 1 : 0,
              control: this.editedItem.control ? 1 : 0,
              incl_sell: this.editedItem.incl_sell ? 1 : 0,
              incl_buy: this.editedItem.incl_buy ? 1 : 0,
              incl_manuf: this.editedItem.incl_manuf ? 1 : 0,
              technique: this.editedItem.technique ? 1 : 0,
              is_null: this.editedItem.is_null ? 1 : 0,
              laize: this.editedItem.laize ? 1 : 0,
              is_packaging: this.editedItem.is_packaging ? 1 : 0,
              val_type: this.editedItem.val_type,
              fk_unit: this.editedItem.fk_unit,
              fk_unit_m2: this.editedItem.fk_unit_m2,
              align: this.editedItem.align,
              key: this.editedItem.key,
              write_uid: this.$store.state.me.id,
            },
          };
          let r = await this.maj(CREATE_ATTRIBUT, v);
          if (r) {
            this.editedItem.id = r.createAttribut.id;
            this.$store.dispatch("Changed", true);
            this.progress = false;
            this.close();
          }
        }
      }
    },
  },
};
</script>
